<template>
    <div class="widgets-wrapper">
        <grid-layout :layout.sync="layout"
            :col-num="col_num"
            :row-height="30"
            :margin="[24,24]"
            :is-draggable="draggable"
            :is-resizable="resizable"
            :responsive="responsive"
            :vertical-compact="true"
            :use-css-transforms="true"
            :cols="{ lg: 12, md: 12, sm: 9, xs: 6, xxs: 2 }"
            ref="WidgetsLayout">

            <grid-item v-for="item in layout"
                :static="item.static"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
                :key="item.i"
                :min-w="item.w"
                :min-h="item.h"
                drag-allow-from=".widget-header-title"
                @resized="resized_event"
                @moved="moved_event">

                <component
                    :is="item.component"
                    src="page"
                    v-bind="item.settings"
                    :no-client-change="noClientChange"
                    :static="item.static"
                    @pin-item="pin_item(item)"
                    @remove-item="remove_item(item)"
                    @save-item="save_item(item, $event)" />

            </grid-item>
        </grid-layout>

        <Library 
            :type="type" 
            :clientid="clientid" 
            :no-client-change="noClientChange"
            @add-new-widget="add_new_widget" />

        <button class="widget-add-btn" @click="call_new_item">
            <svg-icon icon-class="plus_small" />
            Add widget
        </button>
    </div>
</template>

<script>
import {GridLayout, GridItem} from "@/components/GridLayout"
import { mapState } from 'vuex'
import Urls from '@/../config/url.js'
import Library from './Library' 

import GrossAssets from './Library/GrossAssets' 
import CashMovements from './Library/CashMovements' 
import CurrencyExposure from './Library/CurrencyExposure' 

import TopMovers from './Library/TopMovers'
import Drawdown from './Library/Drawdown'

import HighestBeta from './Library/HighestBeta'
import TopBetaChanges from './Library/TopBetaChanges'
import TopBetaContributors from './Library/TopBetaContributors'
import BetaPortfolios from './Library/BetaPortfolios'
import PnlContributors from './Library/PnlContributors'
import PortfoliosPnL from './Library/PortfoliosPnL'

export default {
    components: {
        GridLayout, 
        GridItem,
        Library,

        GrossAssets,
        CashMovements,
        CurrencyExposure,

        TopMovers,
        Drawdown,

        HighestBeta,
        TopBetaChanges,
        TopBetaContributors,
        BetaPortfolios,
        PnlContributors,
        PortfoliosPnL
    },

    props: {
        type: {
            type: String,
            required: true
        },
        clientid: {
            type: String,
            required: false
        },
        noClientChange: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            user_settings_record : undefined,
            layout               : [],
            draggable            : true,
            resizable            : false,
            responsive           : true,
            col_num              : 12,
        }
    },

    computed: {
        ...mapState({
            user : state => state.app.user,
        }),
    },

    methods: {
        get_widgets_list(){
            let query_params = {
                "data.user_id": this.user.id,
                "data.type": this.type,
            }
            let url = Urls.userSettings + "?max_results=1&ts=" + new Date().getTime() + "&where=" + JSON.stringify(query_params)
            this.axios.get(url)
                .then(response => {
                    this.user_settings_record = response.data._items[0]
                    if (this.user_settings_record) {
                        let widgets = this.user_settings_record.widgets
                        if (this.type === 'risk-report') {
                            widgets = widgets.filter(w => { return w.settings.client_id === this.clientid })
                        }
                        this.layout = widgets
                        this.layout.forEach(item => {
                            item.component = item.ref
                        })
                    }
                })
                .catch(e => {
                    this.$store.dispatch('app/process_error', e)
                })
        },

        pin_item(item){
            let index = this.layout.findIndex(x => x.i === item.i);
            item.static = !item.static
            this.$set(this.layout, index, item);
            this.save_widgets(true);
        },

        remove_item(item) {
            this.$confirm('You are going to remove widget. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                const index = this.layout.findIndex(x => x.i === item.i);
                this.layout.splice(index, 1);
                this.save_widgets();
            }).catch(() => {});
        },

        save_item(item, new_settings) {
            let index = this.layout.findIndex(x => x.i === item.i);
            item.settings = new_settings
            this.$set(this.layout, index, item);
            this.save_widgets(true);
        },  

        // todo: delete, depreciated
        add_empty_item(){
            const w = 3
            const h = 6
            let new_coordinates = this.get_new_coordinates(w, h);

            this.layout.push({
                y    : new_coordinates.y,
                x    : new_coordinates.x,
                w    : w,
                h    : h,
                i    : new_coordinates.i,
                type : 'empty',
                static: false
            })
        },

        remove_empty_item(){
            let empty_item_index = this.layout.findIndex(x => x.type === 'empty');
            if (empty_item_index != -1)
                this.layout.splice(empty_item_index, 1);
        },

        call_new_item(){
            this.$store.commit('app/set', {type: 'widgetLibrary', items:true});
        },

        add_new_widget(widget){
            const w = widget.settings.w || 3
            const h = widget.settings.h || 6
            
            delete widget.settings.w
            delete widget.settings.h

            this.remove_empty_item()
            
            let new_coordinates = this.get_new_coordinates(w, h);
            this.layout.push({
                y        : new_coordinates.y,
                x        : new_coordinates.x,
                w        : w,
                h        : h,
                i        : new_coordinates.i,
                static   : false,
                settings : widget.settings,
                ref      : widget.ref,
                component: widget.ref
            })

            this.save_widgets();
        },

        get_new_coordinates(w,h) {
            let x = 0
            let y = 0
            let i = 0

            let cols_num = this.$refs.WidgetsLayout.cols[this.$refs.WidgetsLayout.lastBreakpoint]

            if (this.layout.length) {
                i = Math.max.apply(Math, this.layout.map(function(o) { return o.i; }))

                let maxRow = 0

                this.layout.forEach((selectedWidget) => {
                    if ((selectedWidget.y + selectedWidget.h) >= maxRow)
                        maxRow = selectedWidget.y + selectedWidget.h
                })

                maxRow += 54
                
                let matrix = Array.from({length: maxRow}, () =>
                    Array.from({length: cols_num}, () => true)
                );

                for (let row = 0; row !== maxRow; row++) {
                    for (let col = 0; col !== cols_num; col++) {
                        this.layout.forEach(selectedWidget => {
                            if ((
                                col >= selectedWidget.x &&
                                col <= selectedWidget.x + selectedWidget.w - 1 &&
                                row >= selectedWidget.y &&
                                row <= selectedWidget.y + selectedWidget.h - 1
                            ))
                                matrix[row][col] = false
                        })
                    }
                }

                let isDefined = false
                for (let row = 0; row !== maxRow; row++) {
                    for (let col = 0; col !== cols_num; col++) {
                        if (
                            matrix[row][col] &&
                            matrix[row][col + w - 1] &&
                            matrix[row + h][col] &&
                            matrix[row + h][col + w - 1]
                        ) {
                            x = col
                            y = row
                            isDefined = true
                            break
                        }
                    }

                    if (isDefined)
                        break
                }
            }

            return {
                x: x,
                y: y,
                i: i + 1
            }
        },

        resized_event(){
            this.remove_empty_item()
            this.save_widgets();
        },

        moved_event(){
            this.remove_empty_item()
            this.save_widgets();
        },

        save_widgets(silent){
            if (!silent) this.remove_empty_item();

            const saved_layout = this.layout.filter(w => { return w.type !== 'empty' })
            if (this.user_settings_record) {
                this.axios.patch(Urls.userSettings + this.user_settings_record._id, {
                    widgets: saved_layout,
                },{headers: {
                    'If-Match': this.user_settings_record._etag
                }})
                .then(response => {
                    this.refresh_user_settings_record(response.data._id, silent)
                })
                .catch(e => {
                    this.$store.dispatch('app/process_error', e)
                })
            }
            else {
                this.axios.post(Urls.userSettings, {
                    widgets: saved_layout,
                    data: {
                        type: this.type, 
                        user_id: this.user.id
                    }
                })
                .then(response => {
                    this.refresh_user_settings_record(response.data._id, silent)
                })
                .catch(e => {
                    this.$store.dispatch('app/process_error', e)
                })
            }
        },

        refresh_user_settings_record(record_id, silent){
            this.axios.get(Urls.userSettings + record_id)
                .then(response => {
                    this.user_settings_record = response.data
                })
                .catch(e => {
                    this.$store.dispatch('app/process_error', e)
                })
        },

        rerender(){
            if (this.user.id) this.get_widgets_list();
        }
    },

    mounted(){
        this.$nextTick(() => {
            if (this.user.id) this.get_widgets_list();
            this.$store.dispatch('currency/getList');
            this.$store.dispatch('clients/getList');
        })
    },

    watch: {
        'user.id'(val){
            this.get_widgets_list();
        }
    }
}
</script>
