<template>
    <Widget
        v-bind="$props"
        :preview_data="preview_data"
        :category="category"
        :preview_mode="preview_mode"
        :bar_options="bar_options"
        :table_options="table_options"
        :inner_settings="inner_settings"
        @pin-item="pin_item"
        @remove-item="remove_item"
        @save-item="save_item"
        @add-new-item="add_new_item"
        ref="Widget">

        <template v-slot:form_items="{settings_data}">
            <el-row :gutter="16">
                <el-col :span="12">
                    <el-form-item label="Client" prop="client_id">
                        <el-select v-model="settings_data.client_id" :disabled="noClientChange">
                            <el-option
                                v-for="item in clients_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Portfolio" prop="portfolio_id">
                        <el-select v-model="settings_data.portfolio_id" v-loading="portfolio_list_loading" clearable>
                            <el-option
                                v-for="item in portfolio_list"
                                :key="item._id"
                                :label="item.name"
                                :value="item._id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </template>

    </Widget>
</template>

<script>
import Urls from '@/../config/url.js'
import Widget from './../Widget'
import widget from '@/mixins/widget'
import { num_to_money } from '@/utils'

export default {
    mixins: [widget],
    components : { Widget },

    props: {
        name: {
            type     : String,
            required : false,
            default  : 'Highest Beta'
        },
        display_type: {
            type    : String,
            default : 'table'
        },
        chart_type: {
            type    : String,
            default : 'bar'
        },
        client_id: {
            type : String,
        },
        portfolio_id: {
            type : String,
        },
    },

    data(){
        return {
            preview_data: [
                {'name': 'HAL - Halliburton', 'beta': 2.84},
                {'name': 'ET - Energy Transer', 'beta': 2.53},
                {'name': 'APTV - Aptiv', 'beta': 2.12},
                {'name': 'STLA - Stellantis', 'beta': 1.68},
                {'name': 'BCS - Barclays', 'beta': 1.58},
                {'name': 'MS - Morgan Stanley', 'beta': 1.56},
                {'name': 'ETSY - Etsy', 'beta': 1.43},
                {'name': 'DDAIF - Daimler', 'beta': 1.42},
                {'name': 'MGA - Magna International', 'beta': 1.25},
                {'name': 'AAPL - Apple Inc', 'beta': 0.99},
            ],
            category    : 'Performance',
            init_width  : 3,
            init_height : 6,
        }
    },

    computed: {
        inner_settings(){
            return {}
        },

        bar_options(){
            let x_names = []
            let data = []
            this.widget_data.forEach(r => {
                x_names.push(r.name)
                data.push(r.value)
            })

            let options = {
                xAxis: {
                    type: 'value'
                },
                yAxis: {
                    type: 'category',
                    data: x_names,
                    axisLabel: { show: false },
                },
                series: [ {
                    type: 'bar',
                    data: data,
                    label: {
                        show: true,
                        position: 'insideLeft',
                        fontSize:12,
                        formatter: function(d) {
                            return d.name;
                        }
                    },
                }],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        var tar = params[0];
                        return '<b>' + tar.name + '</b><br>'
                            + 'beta: ' + num_to_money(tar.data,2);
                    }
                }
            }

            return options
        },

        table_options(){
            let options = {
                columns: [
                    {
                        field: 'name',
                        title: 'Name'
                    },
                    {
                        field: 'beta',
                        title: 'Beta',
                        slots: { default: 'col_num' },
                        params   : {
                            format   : {
                                decimals : 2,
                                percents : false,
                                color    : true,
                            },
                        },
                        align:'right',
                    },
                ],
                data: this.widget_data,
            }

            return options
        },
    },

    methods: {
        get_page_widget_data(){
            if (this.src === 'page' && this.client_id) {

                this.loading = true
                let params = {
                    client_id : this.client_id,
                }
                if (this.portfolio_id)
                    params.portfolio_id = this.portfolio_id;

                this.$store.dispatch('widgets/getHighestBeta', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
        },

        process_widget_db_data(data){
            if (data) {
                this.preview_mode = false;
                data.forEach(record => {
                    record.value = record.beta
                })
                this.widget_db_data = data
            }
        },

        check_save_available(){
            if (this.$refs.Widget.settings_data.name && this.$refs.Widget.settings_data.client_id) {
                this.preview_mode = false
                this.loading = true
                let params = {
                    client_id : this.$refs.Widget.settings_data.client_id,
                }
                if (this.$refs.Widget.settings_data.portfolio_id)
                    params.portfolio_id = this.$refs.Widget.settings_data.portfolio_id;

                this.$store.dispatch('widgets/getHighestBeta', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
            else {
                this.preview_mode = true;
            }
        }
    },

}
</script>
