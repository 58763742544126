<template>
    <div class="widget-container">
        <div class="widget-header">
            <div class="widget-header-title">
                {{name}}
                <template v-if="current_route_name === 'home' && client && portfolio && !preview_mode">
                    <br>
                    <span class="widget-header-subtitle">
                        <client-header-link :client="client"/>
                        <portfolio-header-link :clientid="client.id" :portfolio="portfolio" />
                    </span>
                </template>
            </div>

            <div class="widget-header-buttons" v-if="src==='page'">
                <el-button type="text" v-if="!static" @click="pin_item">
                    <svg-icon class="pinned" icon-class="pinned" /> 
                </el-button>
                <el-button type="text" v-if="static" @click="pin_item">
                    <svg-icon class="pinned-fill" icon-class="pinned-fill" /> 
                </el-button>
                <el-button type="text" @click="edit_item">
                    <svg-icon class="settings" icon-class="settings" />  
                </el-button>
                <el-button type="text" @click="remove_item">
                    <svg-icon class="close" icon-class="close" />  
                </el-button>
            </div>
        </div>
        
        <div class="widget-body">
            <Echarts 
                v-if="display_type === 'chart' && chart_type === 'pie'" 
                charttype="pie"
                :options="pie_options"
                :preview="preview_mode" />
            <Echarts 
                v-if="display_type === 'chart' && chart_type === 'bar'" 
                charttype="bar"
                :options="bar_options"
                :preview="preview_mode" />
            <Echarts 
                v-if="display_type === 'chart' && chart_type === 'line'" 
                charttype="line"
                :options="line_options"
                :preview="preview_mode" />
            <Table 
                v-if="display_type === 'table'"
                :options="table_options"
                :preview="preview_mode" />
        </div>
        <div v-if="src === 'library'" class="wl-overlay" @click="open_new_settings"></div>

        <el-dialog
            title="Widget Settings"
            :visible.sync="settings_modal"
            width="95%"
            append-to-body
            custom-class="widget-settings"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            top="30px">

            <div class="ws--body">
                <div class="ws--cols">
                    <div class="ws--preview">
                        <div class="ws--category">{{category}}</div>
                        <div class="ws--preview-container">
                            <div class="widget-header">
                                <div class="widget-header-title">
                                    <div v-if="!name_editor" class="widget-header-title-noeditor">
                                        {{settings_data.name}}
                                        <span @click="edit_asset_name"><svg-icon icon-class="edit" /></span>
                                        <div class="widget-header-preloader" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
                                    </div>
                                    <div v-else class="widget-header-title-editor">
                                        <el-input 
                                            size="small" 
                                            ref="NameInput"
                                            v-model="name_edit_value"
                                            @blur="blur_asset_name"
                                            v-on:keyup.enter="enter_asser_name"
                                            v-on:keyup.native.enter="enter_asser_name" />
                                    </div>
                                </div>
                            </div>

                            <div class="widget-body">
                                <Echarts 
                                    v-if="settings_data.display_type === 'chart' && settings_data.chart_type === 'pie'" 
                                    charttype="pie"
                                    :options="pie_options"
                                    :preview="preview_mode" />
                                <Echarts 
                                    v-if="settings_data.display_type === 'chart' && settings_data.chart_type === 'bar'" 
                                    charttype="bar"
                                    :options="bar_options"
                                    :preview="preview_mode" />
                                <Echarts 
                                    v-if="settings_data.display_type === 'chart' && settings_data.chart_type === 'line'" 
                                    charttype="line"
                                    :options="line_options"
                                    :preview="preview_mode" />
                                <Table 
                                    v-if="settings_data.display_type === 'table'"
                                    :options="table_options"
                                    :preview="preview_mode"
                                    :height="220" />
                            </div>
                        </div>
                    </div>

                    <div class="ws--form">
                        <el-form label-position="top" :model="settings_data" size="small" v-if="settings_data" ref="SettingsForm">
                            <slot name="form_items" v-bind:settings_data="settings_data"></slot>
                        </el-form>
                    </div>
                </div>

                <div class="ws--types">
                    <div class="ws--type" v-if="table_type_available" @click="select_table" v-bind:class="{selected: table_selected}">
                        <div class="ws--type-preview">
                            <svg-icon icon-class="table_view"></svg-icon>
                        </div>
                        <div class="ws--type-name">Table</div>
                    </div>
                    <div class="ws--type" v-if="pie_type_available" @click="select_pie" v-bind:class="{selected: pie_selected}">
                        <div class="ws--type-preview">
                            <svg-icon icon-class="pie_view"></svg-icon>
                        </div>
                        <div class="ws--type-name">Pie Chart</div>
                    </div>
                    <div class="ws--type" v-if="bar_type_available" @click="select_bar" v-bind:class="{selected: bar_selected}">
                        <div class="ws--type-preview">
                            <svg-icon icon-class="bar_view"></svg-icon>
                        </div>
                        <div class="ws--type-name">Bar Chart</div>
                    </div>
                    <div class="ws--type" v-if="line_type_available" @click="select_line" v-bind:class="{selected: line_selected}">
                        <div class="ws--type-preview"></div>
                        <div class="ws--type-name">Line Chart</div>
                    </div>
                </div>

                <div class="ws--footer">
                    <el-button type="primary" plain  class="ws--back" @click="settings_modal=false">
                        <div>
                            <svg-icon icon-class="chevron_big_left" />
                        </div>
                        <div>Back</div>
                    </el-button>
                    <div class="spacer" />
                    <el-button type="primary" class="ws--add" v-if="src==='library'" :disabled="preview_mode" @click="add_new_item">
                        <div>
                            <svg-icon icon-class="plus" />
                        </div>
                        <div>Add</div>
                    </el-button>
                    <el-button type="primary" class="ws--add" v-if="src==='page'" :disabled="preview_mode" @click="save_item">
                        <div>Save</div>
                    </el-button>
                </div>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import Echarts from './Types/Echarts'
import Table from './Types/Table'
import PortfolioHeaderLink from '@/components/PageComponents/PortfolioHeaderLink'
import ClientHeaderLink from '@/components/PageComponents/ClientHeaderLink'

export default {
    components: {
        Echarts,
        Table,
        PortfolioHeaderLink,
        ClientHeaderLink
    },

    props: {
        src: {
            type    : String,
            default : 'page'
        },
        name: {
            type     : String,
            required : false,
            default  : 'Asset Classes'
        },
        display_type: {
            type    : String,
            default : 'table'
        },
        chart_type: {
            type    : String,
            default : 'pie'
        },
        client_id: {
            type : String,
        },
        portfolio_id: {
            type : String,
        },
        static: {
            type    : Boolean,
            default : false,
        },
        noClientChange: {
            type    : Boolean,
            default : false
        },
        preview_data: {
            type     : Array,
            required : true
        },
        preview_mode: {
            type     : Boolean,
            required : true
        },
        category: {
            type     : String,
            required : true
        },
        pie_options: {
            type    : Object,
        },
        bar_options: {
            type    : Object,
        },
        table_options: {
            type    : Object,
        },
        line_options: {
            type    : Object,
        },
        inner_settings: {
            type    : Object,
            default: () => {}
        },
    },

    data(){
        return {
            settings_modal  : false,
            settings_data   : {},

            name_editor     :false,
            name_edit_value : this.name,

            portfolio          : null,
        }
    },

    computed: {
        loading(){
            return this.$store.state['widgets'].dataLoading
        },

        clients(){
            return this.$store.state['clients'].list
        },

        client(){
            let client_found = this.clients.find(client => client._id === this.client_id)
            if(client_found) return client_found
            return null
        },

        current_route_name(){return this.$route.name},

        table_type_available(){
            return (this.table_options) ? true : false
        },
        pie_type_available(){
            return (this.pie_options) ? true : false
        },
        bar_type_available(){
            return (this.bar_options) ? true : false
        },
        line_type_available(){
            return (this.line_options) ? true : false
        },

        pie_selected(){
            return (this.settings_data.display_type === 'chart' && this.settings_data.chart_type === 'pie') ? true : false
        },
        bar_selected(){
            return (this.settings_data.display_type === 'chart' && this.settings_data.chart_type === 'bar') ? true : false
        },
        line_selected(){
            return (this.settings_data.display_type === 'chart' && this.settings_data.chart_type === 'line') ? true : false
        },
        table_selected(){
            return (this.settings_data.display_type === 'table') ? true : false
        },
    },

    methods:{
        open_new_settings(){
            this.settings_modal = true
            this.settings_data = Object.assign({}, {
                name         : this.name,
                display_type : this.display_type,
                chart_type   : this.chart_type,
                client_id    : this.client_id || undefined,
                portfolio_id : this.portfolio_id || undefined,
            }, this.inner_settings);
        },

        pin_item(){
            this.$emit('pin-item')
        },
        edit_item(){
            this.settings_modal = true
            this.settings_data = Object.assign({}, {
                name         : this.name,
                display_type : this.display_type,
                chart_type   : this.chart_type,
                client_id    : this.client_id || undefined,
                portfolio_id : this.portfolio_id || undefined,
            }, this.inner_settings);
        },
        remove_item(){
            this.$emit('remove-item')
        },

        add_new_item(){
            let data = Object.assign({}, this.settings_data);
            this.$emit('add-new-item', data)
        },
        save_item(){
            this.settings_modal = false
            this.$emit('save-item', this.settings_data)
        },

        select_table(){
            this.settings_data.display_type = 'table'
            this.settings_data.chart_type = undefined
        },
        select_pie(){
            this.settings_data.display_type = 'chart'
            this.settings_data.chart_type = 'pie'
        },
        select_bar(){
            this.settings_data.display_type = 'chart'
            this.settings_data.chart_type = 'bar'
        },
        select_line(){
            this.settings_data.display_type = 'chart'
            this.settings_data.chart_type = 'line'
        },

        edit_asset_name(){
            this.name_editor = true;
            this.name_edit_value = this.settings_data.name;
            this.$nextTick(() => {
                this.$refs.NameInput.focus();
            });
            
        },
        enter_asser_name(){
            this.name_editor = false;
            this.settings_data.name = this.name_edit_value;
        },
        blur_asset_name(){
            this.name_editor = false;
            this.name_edit_value = this.settings_data.name;
        },
    },

    mounted(){
        if (this.client_id && this.portfolio_id) {
            //hotfix
            this.$store.dispatch('portfolio/getPortfolio', this.portfolio_id).then((response) => {
                this.portfolio = response
            });
            //должно быть так
            // this.$store.dispatch('clients/getClient', this.client_id).then((client_response) => {
            //     this.client = client_response
            //     this.$store.dispatch('portfolio/getPortfolio', this.portfolio_id).then((portfolio_response) => {
            //         this.portfolio = portfolio_response
            //     })
            // })
        }
    },
}
</script>
