<template>
    <vxe-modal
        v-model="widgetLibrary"
        title="Widgets Collection"
        width="95%"
        destroy-on-close
        :dblclickZoom="false"
        className="widgets-library">

        <el-tabs tab-position="left" v-model="active_tab" v-if="widgetLibrary">
            <el-tab-pane 
                v-for="category in categories" 
                :key="category.type" 
                :label="category.label"
                :name="category.type">
                
                <div class="widgets-library-pane-title">{{category.label}}</div>
                <div class="widgets-library-pane-qty">{{category.list.length}} widgets</div>
                <div class="widgets-library-list">
                    <div v-for="widget in category.list" class="widgets-library-item">
                        <component
                            :is="widget.content"
                            :ref="widget.ref"
                            :client_id="clientid"
                            v-bind="widget.props"
                            src="library"
                            :no-client-change="noClientChange"
                            @add-new-item="add_new_widget(widget, $event)" />
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </vxe-modal>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: true
        },
        clientid: {
            type: String,
            required: false
        },
        noClientChange: {
            type: Boolean,
            default: false
        }
    },

    data(){
        return {
            active_tab: (this.type === 'home') ? 'performance' : 'performance',

            home_categories: [
                {
                    label : 'Performance', 
                    type  : 'performance',
                    list  : [
                        {
                            type    : 'performance', 
                            content : () => import('./Library/GrossAssets'),
                            ref     : 'GrossAssets',
                            props   : { display_type: 'chart', chart_type: 'pie' }
                        },
                        {
                            type    : 'performance', 
                            content : () => import('./Library/CurrencyExposure'),
                            ref     : 'CurrencyExposure',
                            props   : { display_type: 'table' }
                        },
                        {
                            type    : 'performance', 
                            content : () => import('./Library/CashMovements'),
                            ref     : 'CashMovements',
                            props   : { display_type: 'table' }
                        },
                    ]
                },
            ],

            risk_categories: [
                {label : 'Performance', type  : 'performance', list  : [
                    {
                        type    : 'performance', 
                        content : () => import('./Library/TopMovers'),
                        ref     : 'TopMovers',
                        props   : { display_type: 'table' }
                    },
                    {
                        type    : 'performance', 
                        content : () => import('./Library/Drawdown'),
                        ref     : 'Drawdown',
                        props   : { display_type: 'chart' }
                    },
                ]}, 
                {label: 'Beta', type: 'beta', list: [
                    {
                        type    : 'beta',
                        content : () => import('./Library/HighestBeta'),
                        ref     : 'HighestBeta',
                        props   : { display_type: 'table' }
                    },
                    {
                        type    : 'beta',
                        content : () => import('./Library/TopBetaChanges'),
                        ref     : 'TopBetaChanges',
                        props   : { display_type: 'table' }
                    },
                    {
                        type    : 'beta',
                        content : () => import('./Library/TopBetaContributors'),
                        ref     : 'TopBetaContributors',
                        props   : { display_type: 'table' }
                    },
                    {
                        type    : 'beta',
                        content : () => import('./Library/BetaPortfolios'),
                        ref     : 'BetaPortfolios',
                        props   : { display_type: 'table' }
                    },
                ]},
                {label: 'PnL', type: 'pnl', list: [
                    {
                        type    : 'pnl',
                        content : () => import('./Library/PnlContributors'),
                        ref     : 'PnlContributors',
                        props   : { display_type: 'table' }
                    },
                    {
                        type    : 'pnl',
                        content : () => import('./Library/PortfoliosPnL'),
                        ref     : 'PortfoliosPnL',
                        props   : { display_type: 'table' }
                    },
                ]},
            ],
        }
    },

    computed: {
        widgetLibrary: {
            get() { return this.$store.state.app.widgetLibrary; },
            set(value) { this.$store.commit('app/set', {type: 'widgetLibrary', items:value}); },
        },

        categories(){
            if (this.type === 'home')
                return this.home_categories;
            else if (this.type === 'risk-report')
                return this.risk_categories;
            else
                return [];
        }
    },

    methods: {
        add_new_widget(widget, data) {
            this.widgetLibrary = false
            let item = {
                settings : data,
                ref      : widget.ref,
            }
            this.$emit('add-new-widget', item)
        },
    },
}   
</script>
