<template>
    <Widget 
        v-bind="$props"
        :preview_data="preview_data"
        :category="category"
        :preview_mode="preview_mode"
        :pie_options="pie_options"
        :bar_options="bar_options"
        :table_options="table_options"
        :inner_settings="inner_settings"
        @pin-item="pin_item"
        @remove-item="remove_item"
        @save-item="save_item"
        @add-new-item="add_new_item"
        ref="Widget">
        
        <template v-slot:form_items="{settings_data}">
            <el-row :gutter="16">
                <el-col :span="12">
                    <el-form-item label="Client" prop="client_id">
                        <el-select v-model="settings_data.client_id" :disabled="noClientChange">
                            <el-option
                                v-for="item in clients_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Portfolio" prop="portfolio_id">
                        <el-select v-model="settings_data.portfolio_id" v-loading="portfolio_list_loading" clearable>
                            <el-option
                                v-for="item in portfolio_list"
                                :key="item._id"
                                :label="item.name"
                                :value="item._id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="16">
                <el-col :span="6" v-if="!settings_data.portfolio_id">
                    <el-form-item label="Portfolio Currency" prop="portf_currency">
                        <el-select v-model="settings_data.portf_currency">
                            <el-option
                                v-for="item in currency_list"
                                :key="item.code"
                                :label="item.code"
                                :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </template>
    </Widget>
</template>

<script>
import { mapState } from 'vuex'
import Urls from '@/../config/url.js'
import Widget from './../Widget'
import widget from '@/mixins/widget'
import {num_to_money} from "@/utils";

export default {
    mixins: [widget],
    components : { Widget },

    props: {
        name: {
            type     : String,
            required : false,
            default  : 'Gross Assets'
        },
        display_type: {
            type    : String,
            default : 'table'
        },
        chart_type: {
            type    : String,
            default : 'pie'
        },
        client_id: {
            type : String,
        },
        portfolio_id: {
            type : String,
        },
        portf_currency: {
            type : String,
            default: 'USD'
        }
    },

    data(){
        return {
            preview_data: [
                {value: 53, name: 'Fixed Income'},
                {value: 21, name: 'Equity'},
                {value: 24.8, name: 'Cash'},
                {value: 1.2, name: 'Other'},
            ],
            category    : 'Performance',
            init_width  : 3,
            init_height : 6,
        }
    },

    computed: {
        inner_settings(){
            return {
                'portf_currency' : this.portf_currency, 
            }
        },

        pie_options(){
            let options = {
                legend: {
                    orient: 'vertical',
                    right: 5,
                    top: 0,
                    bottom: 20,
                },

                series: [{
                    type: 'pie',
                    center: ['35%', '50%'],
                    label: {
                        show:false
                    },
                    labelLine: {
                        show: false
                    },
                    data: this.widget_data,
                    animationEasing: 'cubicInOut',
                    animationDuration: 2600
                }],

                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        return `<b>${params.name}</b><br>
                            %%: ${num_to_money(params.value,2)}<br>`
                    }
                },
            }
            return options
        },

        table_options(){
            let options = {
                columns: [
                    { 
                        field: 'name', 
                        title: 'asset' 
                    },{ 
                        field: 'mv', 
                        title: 'market value', 
                        slots: { default: 'col_num' },
                        params   : {
                            format   : {
                                decimals  : 2,
                                zeros     : true,
                                color     : true,
                            }
                        },
                        align:'right' 
                    },{ 
                        field: 'value', 
                        title: 'weight', 
                        slots: { default: 'col_num' },
                        params   : {
                            format   : {
                                decimals : 2,
                                percents : true,
                                color    : true,
                            },
                        },
                        align:'right' 
                    },
                ],
                data: this.widget_data,
            }

            return options
        },

        bar_options(){
            let x_names = []
            let data = []
            this.widget_data.forEach(r => {
                x_names.push(r.name)
                data.push(r.value)
            })

            let options = {
                xAxis: {
                    type: 'category',
                    data: x_names,
                },
                yAxis: {
                    type: 'value'
                },
                series: [ {
                    type: 'bar',
                    data: data,
                }],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        var tar = params[0];
                        return `<b>${tar.name}</b><br>
                            %%: ${num_to_money(tar.value,2)}<br>`
                    }
                },
            }

            return options
        }
    },

    methods: {
        get_page_widget_data(){
            if (this.src === 'page' && this.client_id) {

                this.loading = true
                let params = {
                    client_id : this.client_id,
                }
                if (this.portfolio_id)
                    params.portfolio_id = this.portfolio_id;
                else
                    params.portf_currency = this.portf_currency;

                this.$store.dispatch('widgets/getGrossAssets', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
        },

        process_widget_db_data(data){
            this.preview_mode = false;
            this.widget_db_data = data
        },

        check_save_available(){
            if (this.$refs.Widget.settings_data.name && this.$refs.Widget.settings_data.client_id) {
                this.preview_mode = false
                this.loading = true
                let params = {
                    client_id : this.$refs.Widget.settings_data.client_id,
                }
                if (this.$refs.Widget.settings_data.portfolio_id)
                    params.portfolio_id = this.$refs.Widget.settings_data.portfolio_id;
                else
                    params.portf_currency = this.$refs.Widget.settings_data.portf_currency;

                this.$store.dispatch('widgets/getGrossAssets', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
            else {
                this.preview_mode = true;
            }
        }
    },
}
</script>
