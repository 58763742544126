<template>
    <Widget 
        v-bind="$props"
        :preview_data="preview_data"
        :category="category"
        :preview_mode="preview_mode"
        :table_options="table_options"
        :inner_settings="inner_settings"
        @pin-item="pin_item"
        @remove-item="remove_item"
        @save-item="save_item"
        @add-new-item="add_new_item"
        ref="Widget">

        <template v-slot:form_items="{settings_data}">
            <el-row :gutter="16">
                <el-col :span="12">
                    <el-form-item label="Client" prop="client_id">
                        <el-select v-model="settings_data.client_id" :disabled="noClientChange">
                            <el-option
                                v-for="item in clients_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Portfolio" prop="portfolio_id">
                        <el-select v-model="settings_data.portfolio_id" v-loading="portfolio_list_loading" clearable>
                            <el-option
                                v-for="item in portfolio_list"
                                :key="item._id"
                                :label="item.name"
                                :value="item._id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="16">
                <el-col :span="6">
                    <el-form-item label="Records Limit" prop="limit">
                        <el-select v-model="settings_data.limit">
                            <el-option label="5" :value="5"></el-option>
                            <el-option label="10" :value="10"></el-option>
                            <el-option label="15" :value="15"></el-option>
                            <el-option label="20" :value="20"></el-option>
                            <el-option label="25" :value="25"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </template>

    </Widget>
</template>

<script>
import Urls from '@/../config/url.js'
import Widget from './../Widget'
import widget from '@/mixins/widget'
import { date_to_string } from '@/utils'

export default {
    mixins: [widget],
    components : { Widget },

    props: {
        name: {
            type     : String,
            required : false,
            default  : 'Last Cash Movements'
        },
        display_type: {
            type    : String,
            default : 'table'
        },
        client_id: {
            type : String,
        },
        portfolio_id: {
            type : String,
        },
        limit: {
            default: 10
        },
    },

    data(){
        return {
            preview_data: [
                {date: '10 Nov 20', label: 'Interest paid Gazpru 24 4.25%', debit: 0.000, credit: 70000.000, portfolio: 'AAA', 'currency':'USD'},
                {date: '6 Nov 20', label: 'Sberbank divs', debit: 0.000, credit: 120000.000, portfolio: 'AAA', 'currency':'RUB'},
                {date: '29 Sep 20', label: 'Outgoing payment OOO Romashka', debit: -400000.000, credit: 0.000, portfolio: 'AAA', 'currency':'RUB'},
                {date: '20 Sep 20', label: 'Bonus payment', debit: 0.000, credit: 5.000, portfolio: 'CCC', 'currency':'EUR'},
                {date: '15 Sep 20', label: 'Performance Fee', debit: -250000.000, credit: 0.000, portfolio: 'AAA', 'currency':'USD'},
            ],
            category    : 'Performance',
            init_width  : 6,
            init_height : 6,
        }
    },

    computed: {
        inner_settings(){
            return {
                'limit'  : this.limit,
            }
        },

        table_options(){
            let columns = [
                { 
                    field: 'date', 
                    title: 'date',
                    width: '80' 
                },
                { 
                    field: 'label', 
                    title: 'label',
                    minWidth: '80'
                },
                { 
                    field: 'debit', 
                    title: 'debit', 
                    slots: { default: 'col_num' },
                    params   : {
                        format   : {
                            decimals : 2,
                            color    : true,
                        },
                    },
                    align:'right',
                    width: '80'
                },
                { 
                    field: 'credit', 
                    title: 'credit', 
                    slots: { default: 'col_num' },
                    params   : {
                        format   : {
                            decimals : 2,
                            color    : true,
                        },
                    },
                    align:'right',
                    width: '80'
                },
                { 
                    field: 'currency', 
                    title: 'curr.',
                    width: '60'
                },
                { 
                    field: 'portfolio', 
                    title: 'portfolio',
                    minWidth: '80' 
                },
            ]

            let options = {
                columns: columns,
                data: this.widget_data,
            }

            return options
        },
    },

    methods: {
        get_page_widget_data(){
            if (this.src === 'page' && this.client_id && this.limit) {
                this.loading = true
                let params = {
                    client_id : this.client_id,
                    limit     : this.limit,
                }
                if (this.portfolio_id)
                    params.portfolio_id = this.portfolio_id;

                this.$store.dispatch('widgets/getCashMovements', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
        },

        process_widget_db_data(data){
            if (data) {
                this.preview_mode = false;
                let db_data = []
                data.forEach(record => {
                    let debit = 0
                    let credit = 0
                    if (record.q > 0) {
                        debit = record.q
                    }
                    else {
                        credit = record.q
                    }
                    let label = ''
                    if (record.operation) {
                        label = record.operation.isin +' '+ record.operation.t 
                    }
                    else {
                        label = record.f
                    }
                    db_data.push({ 
                        date: date_to_string(record.dt, 'DD MMM YY'), 
                        label: label, 
                        debit: debit, 
                        credit: credit, 
                        portfolio: record.portfolio_name, 
                        'currency':record.isin
                    })
                })
                this.widget_db_data = db_data
            }
        },

        check_save_available(){
            if (this.$refs.Widget.settings_data.name 
                && this.$refs.Widget.settings_data.client_id 
                && this.$refs.Widget.settings_data.limit
            ) {
                this.preview_mode = false
                this.loading = true
                let params = {
                    client_id : this.$refs.Widget.settings_data.client_id,
                    limit     : this.$refs.Widget.settings_data.limit,
                }
                if (this.$refs.Widget.settings_data.portfolio_id)
                    params.portfolio_id = this.$refs.Widget.settings_data.portfolio_id;
                this.$store.dispatch('widgets/getCashMovements', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
            else {
                this.preview_mode = true;
            }
        }
    },
    
}
</script>
