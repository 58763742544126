import { mapState } from 'vuex'

export default {
    props:{
        src: {
            type    : String,
            default : 'page'
        },
        static: {
            type    : Boolean,
            default : false,
        },
        noClientChange: {
            type    : Boolean,
            default : false
        }
    },

    data(){
        return {
            widget_db_data : [],
            preview_mode   : true,
            portfolio_list: [],
            portfolio_list_loading: false,
        }
    },

    computed: {
        ...mapState({
            clients_options : state => state.clients.options,
            currency_list : state => state.currency.list,
        }),

        loading: {
            get() { return this.$store.state.widgets.dataLoading; },
            set(value) { this.$store.commit('widgets/set', {type: 'dataLoading', items:value}); },
        },

        widget_data(){
            let data = []
            if (this.preview_mode) {
                data = this.preview_data
            }
            else if (!this.$refs.Widget.settings_modal && this.client_id) {
                data = this.widget_db_data
            }
            else if (this.$refs.Widget.settings_modal && this.$refs.Widget.settings_data.client_id) {
                data = this.widget_db_data
            }
            return data
        },
    },

    methods: {
        pin_item(){
            this.$emit('pin-item')
        },
        remove_item(){
            this.$emit('remove-item')
        },
        save_item(data){
            this.$emit('save-item', data)
        },
        add_new_item(data){
            data.w = this.init_width
            data.h = this.init_height
            this.$emit('add-new-item', data)
        },

        get_portfolios_list(){
            this.portfolio_list = []
            if (!this.$refs.Widget.settings_data.client_id)
                return;

            this.loading = true
            this.portfolio_list_loading = true

            this.$store.dispatch('portfolio/getList', {client_id: this.$refs.Widget.settings_data.client_id}).then((response) => {
                this.loading = false
                this.portfolio_list_loading = false
                this.portfolio_list = response

                if (this.$refs.Widget.settings_data.portfolio_id) {
                    let exist = this.portfolio_list.find(x => x._id === this.$refs.Widget.settings_data.portfolio_id);
                    if (!exist) this.$refs.Widget.settings_data.portfolio_id = undefined;
                }

            })
        },
    },

    mounted() {
        this.get_page_widget_data()

        this.$watch(
            "$refs.Widget.settings_modal", (value) => {
                if (value) {
                    this.$store.dispatch('clients/getOptions').then(() => {
                        this.get_portfolios_list()
                    })
                }
                else {
                    if (this.src === 'library') this.preview_mode = true;
                    this.get_page_widget_data()
                }
            }
        );

        this.$watch(
            "$refs.Widget.settings_data.client_id", (value) => {
                this.get_portfolios_list()
            }
        );

        this.$watch(
            "$refs.Widget.settings_data", (value) => {
                this.check_save_available();
            }, {
              deep: true
            }
        );
    }
}
