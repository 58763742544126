<template>
    <div class="prtf-page-header-subtitle_item link" @click="open_portfolio_page">
        <svg-icon icon-class="portfolio" />
        {{portfolio.name}}
    </div>
</template>

<script>
export default {
    props: {
        portfolio: {
            required : true,
            type     : Object,
        },
        clientid: {
            required : true,
            type     : Number,
        },
    },

    methods: {
        open_portfolio_page(){
            this.$router.push('/portfolio/' + this.clientid + '/' + this.portfolio._id)
        }
    }
}
</script>
