<template>
    <Widget
        v-bind="$props"
        :preview_data="preview_data"
        :category="category"
        :preview_mode="preview_mode"
        :bar_options="bar_options"
        :table_options="table_options"
        :inner_settings="inner_settings"
        @pin-item="pin_item"
        @remove-item="remove_item"
        @save-item="save_item"
        @add-new-item="add_new_item"
        ref="Widget">

        <template v-slot:form_items="{settings_data}">
            <el-row :gutter="16">
                <el-col :span="12">
                    <el-form-item label="Client" prop="client_id">
                        <el-select v-model="settings_data.client_id" :disabled="noClientChange">
                            <el-option
                                v-for="item in clients_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
<!--                 <el-col :span="12">
                    <el-form-item label="Portfolio" prop="portfolio_id">
                        <el-select v-model="settings_data.portfolio_id" v-loading="portfolio_list_loading" clearable>
                            <el-option
                                v-for="item in portfolio_list"
                                :key="item._id"
                                :label="item.name"
                                :value="item._id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="16"> -->
                <el-col :span="6">
                    <el-form-item label="Period" prop="period">
                        <el-select v-model="settings_data.period">
                            <el-option label="1 Day" value="1d"></el-option>
                            <el-option label="1 Week" value="1w"></el-option>
                            <el-option label="1 Month" value="1m"></el-option>
                            <el-option label="1 Year" value="1y"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="View Mode" prop="mode">
                        <el-select v-model="settings_data.mode">
                            <el-option label="Best" value="best"></el-option>
                            <el-option label="Worst" value="worst"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </template>

    </Widget>
</template>

<script>
import Urls from '@/../config/url.js'
import Widget from './../Widget'
import widget from '@/mixins/widget'
import { num_to_money } from '@/utils'

export default {
    mixins: [widget],
    components : { Widget },

    props: {
        name: {
            type     : String,
            required : false,
            default  : 'Portfolios Pnl'
        },
        display_type: {
            type    : String,
            default : 'table'
        },
        chart_type: {
            type    : String,
            default : 'bar'
        },
        client_id: {
            type : String,
        },
        portfolio_id: {
            type : String,
        },
        mode: {
            type : String,
            default: 'best'
        },
        period: {
            type : String,
            default: '1d'
        },
    },

    data(){
        return {
            preview_data: [
                { name: 'Portfolio 1', pnl: 1207007},
                { name: 'Portfolio 2', pnl: 1088227},
                { name: 'Portfolio 3', pnl: 993339},
                { name: 'Portfolio 4', pnl: -17960},
                { name: 'Portfolio 5', pnl: -17856},
            ],
            category    : 'Performance',
            init_width  : 3,
            init_height : 6,
        }
    },

    computed: {
        inner_settings(){
            return {
                'mode'   : this.mode,
                'period' : this.period,
            }
        },

        bar_options(){
            let x_names = []
            let data = []
            this.widget_data.forEach(r => {
                x_names.push(r.name)
                data.push(r.pnl)
            })

            let options = {
                xAxis: {
                    type: 'value',
                    axisLabel: { rotate: 20 },
                },
                yAxis: {
                    type: 'category',
                    data: x_names,
                    axisLabel: { show: false },
                },
                series: [
                    {
                        type: 'bar',
                        data: data,
                        label: {
                            show: true,
                            position: 'insideLeft',
                            fontSize:12,
                            formatter: function(d) {
                                return d.name;
                            }
                        },
                    },
                ],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        var tar = params[0];
                        return '<b>' + tar.name + '</b><br>'
                            + 'PNL: ' + num_to_money(tar.data,2);
                    }
                }
            }

            return options
        },

        table_options(){
            let options = {
                columns: [
                    {
                        field: 'name',
                        title: 'Name',
                        minWidth: '30%'
                    },
                    {
                        field: 'pnl',
                        title: 'PnL ($)',
                        slots: { default: 'col_num' },
                        params   : {
                            format   : {
                                decimals : 2,
                                color    : true,
                                sign     : true,
                            },
                        },
                        align:'right',
                    },
                ],
                data: this.widget_data,
                footerMethod: this.footer_method,
                showFooter: true,
                footerRowClassName: 'widget-table-total'
            }

            return options
        },
    },

    methods: {
        footer_method(){
            let total = 0;

            this.widget_data.map(row => total += row.pnl)

            return [['Total', num_to_money(total, 2)]]
        },

        get_page_widget_data(){
            if (this.src === 'page' && this.client_id && this.mode && this.period) {

                this.loading = true
                let params = {
                    client_id : this.client_id,
                    mode: this.mode,
                    period: this.period
                }
                if (this.portfolio_id)
                    params.portfolio_id = this.portfolio_id;

                this.$store.dispatch('widgets/getPortfoliosPnl', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
        },

        process_widget_db_data(data){
            if (data) {
                this.preview_mode = false;
                this.widget_db_data = data
            }
        },

        check_save_available(){
            if (
                this.$refs.Widget.settings_data.name
                && this.$refs.Widget.settings_data.client_id
                && this.$refs.Widget.settings_data.mode
                && this.$refs.Widget.settings_data.period
            ) {
                this.preview_mode = false
                this.loading = true
                let params = {
                    client_id : this.$refs.Widget.settings_data.client_id,
                    mode: this.$refs.Widget.settings_data.mode,
                    period: this.$refs.Widget.settings_data.period
                }
                if (this.$refs.Widget.settings_data.portfolio_id)
                    params.portfolio_id = this.$refs.Widget.settings_data.portfolio_id;

                this.$store.dispatch('widgets/getPortfoliosPnl', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
            else {
                this.preview_mode = true;
            }
        }
    },

}
</script>
