<template>
    <Widget 
        v-bind="$props"
        :preview_data="preview_data"
        :category="category"
        :preview_mode="preview_mode"
        :bar_options="bar_options"
        :table_options="table_options"
        :inner_settings="inner_settings"
        @pin-item="pin_item"
        @remove-item="remove_item"
        @save-item="save_item"
        @add-new-item="add_new_item"
        ref="Widget">

        <template v-slot:form_items="{settings_data}">
            <el-row :gutter="16">
                <el-col :span="12">
                    <el-form-item label="Client" prop="client_id">
                        <el-select v-model="settings_data.client_id" :disabled="noClientChange">
                            <el-option
                                v-for="item in clients_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Portfolio" prop="portfolio_id">
                        <el-select v-model="settings_data.portfolio_id" v-loading="portfolio_list_loading" clearable>
                            <el-option
                                v-for="item in portfolio_list"
                                :key="item._id"
                                :label="item.name"
                                :value="item._id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="16">
                <el-col :span="6">
                    <el-form-item label="Period" prop="period">
                        <el-select v-model="settings_data.period">
                            <el-option label="1 Day" value="1d"></el-option>
                            <el-option label="1 Week" value="1w"></el-option>
                            <el-option label="1 Month" value="1m"></el-option>
                            <el-option label="1 Year" value="1y"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="View Mode" prop="mode">
                        <el-select v-model="settings_data.mode">
                            <el-option label="Winners" value="winners"></el-option>
                            <el-option label="Loosers" value="loosers"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="Records Limit" prop="limit">
                        <el-select v-model="settings_data.limit">
                            <el-option label="5" :value="5"></el-option>
                            <el-option label="10" :value="10"></el-option>
                            <el-option label="15" :value="15"></el-option>
                            <el-option label="20" :value="20"></el-option>
                            <el-option label="25" :value="25"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </template>

    </Widget>
</template>

<script>
import {mapState} from 'vuex'
import Urls from '@/../config/url.js'
import Widget from './../Widget'
import widget from '@/mixins/widget'
import { num_to_money } from '@/utils'

export default {
    mixins: [widget],
    components : { Widget },

    props: {
        name: {
            type     : String,
            required : false,
            default  : 'Top Movers'
        },
        display_type: {
            type    : String,
            default : 'table'
        },
        chart_type: {
            type    : String,
            default : 'bar'
        },
        client_id: {
            type : String,
        },
        portfolio_id: {
            type : String,
        },
        period: {
            type : String,
            default: '1d'
        },
        limit: {
            default: 10
        },
        mode: {
            type : String,
            default: 'winners'
        }
    },

    data(){
        return {
            preview_data: [
                {value: 9.11, name: 'HAL - Halliburton'},
                {value: 9.02, name: 'ET - Energy Transer'},
                {value: 8.82, name: 'APTV - Aptiv'},
                {value: 6.3879637190527, name: 'STLA - Stellantis'},
                {value: 6.13, name: 'BCS - Barclays'},
            ],
            category    : 'Performance',
            init_width  : 3,
            init_height : 6,
        }
    },

    computed: {
        ...mapState({
            current_theme: state => state.themes.current_theme
        }),

        inner_settings(){
            return {
                'period' : this.period, 
                'limit'  : this.limit,
                'mode'   : this.mode
            }
        },

        table_options(){
            let options = {
                columns: [
                    { 
                        field: 'name', 
                        title: 'name' 
                    },
                    { 
                        field: 'value', 
                        title: 'price change', 
                        slots: { default: 'col_num' },
                        params   : {
                            format   : {
                                decimals : 2,
                                percents : true,
                                color    : true,
                            },
                        },
                        align:'right',
                        width: '80'
                    },
                ],
                data: this.widget_data,
            }

            return options
        },

        bar_options(){
            let x_names = []
            let data = []
            this.widget_data.forEach(r => {
                x_names.push(r.name)
                data.push(r.value)
            })

            let options = {
                xAxis: {
                    type: 'value',
                },
                yAxis: {
                    type: 'category',
                    data: x_names,
                    axisLabel: { show: false },
                },
                series: [ {
                    type: 'bar',
                    data: data,
                    label: {
                        show: true,
                        formatter: function(d) {
                            return d.name;
                        }
                    },
                }],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        var tar = params[0];
                        return '<b>' + tar.name + '</b><br>'
                            + 'price change, %: ' + num_to_money(tar.data,2);
                    }
                }
            }

            return options
        }
    },

    methods: {
        get_page_widget_data(){
            if (this.src === 'page' && this.client_id && this.period && this.limit && this.mode) {
                this.loading = true
                let params = {
                    client_id : this.client_id,
                    period    : this.period,
                    limit     : this.limit,
                    mode      : this.mode,
                }
                if (this.portfolio_id)
                    params.portfolio_id = this.portfolio_id;

                this.$store.dispatch('widgets/getTopMovers', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
        },

        process_widget_db_data(data){
            if (data) {
                this.preview_mode = false;
                let db_data = []
                data.forEach(record => {
                    let name = record.name
                    if (record.ticker)
                        name = record.ticker + ' - ' + name;
                    else if (record.asset === 'cash')
                        name = record.isin + ' - ' + name;
                    db_data.push({ name: name, value: record.value })
                })
                this.widget_db_data = db_data
            }
        },

        check_save_available(){
            if (this.$refs.Widget.settings_data.name 
                && this.$refs.Widget.settings_data.client_id 
                && this.$refs.Widget.settings_data.period
                && this.$refs.Widget.settings_data.limit
                && this.$refs.Widget.settings_data.mode
            ) {
                this.preview_mode = false
                this.loading = true
                let params = {
                    client_id : this.$refs.Widget.settings_data.client_id,
                    period    : this.$refs.Widget.settings_data.period,
                    limit     : this.$refs.Widget.settings_data.limit,
                    mode      : this.$refs.Widget.settings_data.mode,
                }
                if (this.$refs.Widget.settings_data.portfolio_id)
                    params.portfolio_id = this.$refs.Widget.settings_data.portfolio_id;
                this.$store.dispatch('widgets/getTopMovers', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
            else {
                this.preview_mode = true;
            }
        }
    },
    
}
</script>
