<template>
    <Widget
        v-bind="$props"
        :preview_data="preview_data"
        :category="category"
        :preview_mode="preview_mode"
        :line_options="line_options"
        :inner_settings="inner_settings"
        @pin-item="pin_item"
        @remove-item="remove_item"
        @save-item="save_item"
        @add-new-item="add_new_item"
        ref="Widget">

        <template v-slot:form_items="{settings_data}">
            <el-row :gutter="16">
                <el-col :span="12">
                    <el-form-item label="Client" prop="client_id">
                        <el-select v-model="settings_data.client_id" :disabled="noClientChange">
                            <el-option
                                v-for="item in clients_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Portfolio" prop="portfolio_id">
                        <el-select v-model="settings_data.portfolio_id" v-loading="portfolio_list_loading" clearable>
                            <el-option
                                v-for="item in portfolio_list"
                                :key="item._id"
                                :label="item.name"
                                :value="item._id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="16">
                <el-col :span="6">
                    <el-form-item label="Period" prop="period">
                        <el-select v-model="settings_data.period">
                            <el-option label="1 Week" value="1w"></el-option>
                            <el-option label="1 Month" value="1m"></el-option>
                            <el-option label="1 Year" value="1y"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="Limit" prop="limit">
                        <el-input v-model="settings_data.limit" type="number"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </template>

    </Widget>
</template>

<script>
import Urls from '@/../config/url.js'
import Widget from './../Widget'
import widget from '@/mixins/widget'
import { num_to_money, date_to_string } from '@/utils'

export default {
    mixins: [widget],
    components : { Widget },

    props: {
        name: {
            type     : String,
            required : false,
            default  : 'Drawdown'
        },
        display_type: {
            type    : String,
            default : 'chart'
        },
        chart_type: {
            type    : String,
            default : 'line'
        },
        client_id: {
            type : String,
        },
        portfolio_id: {
            type : String,
        },
        period: {
            type : String,
            default: '1m'
        },
        limit: {
            default: 10
        },
    },

    data(){
        return {
            preview_data: [
                { dt: '2021-11-01', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-02', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4)},
                { dt: '2021-11-03', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-04', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-05', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-06', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-07', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-08', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-09', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-10', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-11', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-12', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-13', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-14', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-15', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-16', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-17', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-18', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-19', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-20', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-21', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-22', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-23', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-24', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-25', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-26', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-27', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-28', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-29', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
                { dt: '2021-11-30', drawdown: (Math.random() * (-12.0000 - 0.0000) + 0.0200).toFixed(4) },
            ],
            category    : 'Performance',
            init_width  : 6,
            init_height : 6,
        }
    },

    computed: {
        inner_settings(){
            return {
                'period' : this.period, 
                'limit'  : this.limit,
            }
        },

        line_options(){
            let x_names = []
            let data = []
            let limit_data = []
            let min_y = 0
            let limit = this.inner_settings.limit * -1
            this.widget_data.forEach(r => {
                x_names.push(r.dt)
                limit_data.push(limit)
                if (r.drawdown < min_y) min_y = r.drawdown;
                data.push(r.drawdown)
            })
            if (limit < min_y) min_y = limit;
            min_y = Math.round(min_y - 2)

            let options = {
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: x_names
                },
                yAxis: {
                    type: 'value',
                    min: min_y,
                },
                series: [
                    {
                        data: data,
                        type: 'line',
                        color: "#0079FA",
                        areaStyle: {
                            color: "#0079FA"
                        },
                        lineStyle: {
                            width: 1,
                            color: "#0079FA"
                        },
                    },{
                        data: limit_data,
                        type: 'line',
                        areaStyle: {
                            opacity:0.1,
                            color: "#2EAE9F"
                        },
                        lineStyle: {
                            width: 1,
                            color: "red"
                        },
                        showSymbol: false,
                    }
                ],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        var tar = params[0];
                        return '<b>' + tar.name + '</b><br>'
                            + 'drawdown, %: ' + num_to_money(tar.data,2);
                    }
                }
            }

            return options
        },
    },

    methods: {
        get_page_widget_data(){
            if (this.src === 'page' && this.client_id && this.portfolio_id && this.period && this.limit) {
                this.loading = true
                let params = {
                    client_id    : this.client_id,
                    portfolio_id : this.portfolio_id,
                    period       : this.period,
                }

                this.$store.dispatch('widgets/getDrawdown', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
        },

        process_widget_db_data(data){
            if (data) {
                this.preview_mode = false;
                data.forEach(record => {
                    record.dt = date_to_string(record.dt, 'DD MMM YY')
                    record.drawdown = record.drawdown * 100
                })
                this.widget_db_data = data
            }
        },

        check_save_available(){
            if (this.$refs.Widget.settings_data.name && this.$refs.Widget.settings_data.client_id && this.$refs.Widget.settings_data.portfolio_id && this.$refs.Widget.settings_data.period && this.$refs.Widget.settings_data.limit) {
                this.preview_mode = false
                this.loading = true
                let params = {
                    client_id    : this.$refs.Widget.settings_data.client_id,
                    portfolio_id : this.$refs.Widget.settings_data.portfolio_id,
                    period       : this.$refs.Widget.settings_data.period,
                }

                this.$store.dispatch('widgets/getDrawdown', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
            else {
                this.preview_mode = true;
            }
        }
    },

}
</script>
