<template>
    <div style="position:relative;height: calc(100% - 6px); padding-bottom:6px;" v-resize @resize="onContainerResize" ref="SomeRef">
        <vxe-grid v-bind="gridOptions" v-if="tableInited" auto-resize>
            <template v-slot:col_num="{ row, column }">
                <FormattedNumber
                    :value="row[column.property]"
                    :decimals="column.params.format.decimals"
                    :percents="column.params.format.percents"
                    :zeros="column.params.format.zeros"
                    :sign="column.params.format.sign"
                    :color="column.params.format.color"
                    :format="column.params.format.format"
                     />
            </template>
        </vxe-grid>
<!--        <div class="echart-preview-overlay" v-if="preview"></div>-->
    </div>
</template>

<script>
import FormattedNumber from '@/components/Formats/FormattedNumber'
export default {
    components: { FormattedNumber },
    props: {
        options: {
            type    : Object,
            default : () => {},
        },
        preview: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number,
        },
    },

    data(){
        return {
            maxTableHeight: 1,
            tableInited: false,
        }
    },

    computed: {
        gridOptions(){
            let options = {
                border: 'inner',
                resizable: false,
                showOverflow: true,
                showHeaderOverflow: true,
                autoResize: false,
                size: 'mini',
                stripe: true,
                height: this.maxTableHeight,
            }

            return {...this.options, ...options};
        },
    },

    methods: {
        initTable(){
            this.getMaxTableHeight()
            this.tableInited = true
        },
        getMaxTableHeight(){
            let titleHeight = this.$parent.$el.querySelector(".widget-header-title").offsetHeight
            this.maxTableHeight = (this.height) ? this.height : (this.$parent.$el.offsetHeight - titleHeight - 44)
        },
        onContainerResize(){
            this.getMaxTableHeight()
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.initTable();
        })
    },

    watch: {
        options(val) {
            this.initTable();
        },
    }
}
</script>
