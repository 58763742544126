<template>
    <Widget 
        v-bind="$props"
        :preview_data="preview_data"
        :category="category"
        :preview_mode="preview_mode"
        :pie_options="pie_options"
        :bar_options="bar_options"
        :table_options="table_options"
        :inner_settings="inner_settings"
        @pin-item="pin_item"
        @remove-item="remove_item"
        @save-item="save_item"
        @add-new-item="add_new_item"
        ref="Widget">

        <template v-slot:form_items="{settings_data}">
            <el-row :gutter="16">
                <el-col :span="12">
                    <el-form-item label="Client" prop="client_id">
                        <el-select v-model="settings_data.client_id" :disabled="noClientChange">
                            <el-option
                                v-for="item in clients_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Portfolio" prop="portfolio_id">
                        <el-select v-model="settings_data.portfolio_id" v-loading="portfolio_list_loading" clearable>
                            <el-option
                                v-for="item in portfolio_list"
                                :key="item._id"
                                :label="item.name"
                                :value="item._id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="16">
                <el-col :span="6" v-if="!settings_data.portfolio_id">
                    <el-form-item label="Portfolio Currency" prop="currency">
                        <el-select v-model="settings_data.currency">
                            <el-option
                                v-for="item in currency_list"
                                :key="item.code"
                                :label="item.code"
                                :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </template>

    </Widget>
</template>

<script>
import Urls from '@/../config/url.js'
import Widget from './../Widget'
import widget from '@/mixins/widget'
import { num_to_money } from '@/utils'

export default {
    mixins: [widget],
    components : { Widget },

    props: {
        name: {
            type     : String,
            required : false,
            default  : 'Currency Exposure'
        },
        display_type: {
            type    : String,
            default : 'table'
        },
        chart_type: {
            type    : String,
            default : 'pie'
        },
        client_id: {
            type : String,
        },
        portfolio_id: {
            type : String,
        },
        currency: {
            type : String,
            default: 'USD'
        }
    },

    data(){
        return {
            preview_data: [
                {'currency': 'USD', 'gross_assets_curr': 100000, 'gross_assets_base_curr': 100000, 'percent':3.07, name: 'USD', 'value': 3.07},
                {'currency': 'EUR', 'gross_assets_curr': 2000000, 'gross_assets_base_curr': 2340000, 'percent':71.83, name: 'EUR', 'value': 71.83},
                {'currency': 'CHF', 'gross_assets_curr': 500000, 'gross_assets_base_curr': 550000, 'percent':16.88, name: 'CHF', 'value': 16.88},
                {'currency': 'RUB', 'gross_assets_curr': 20000000, 'gross_assets_base_curr': 250000, 'percent':7.67, name: 'RUB', 'value': 7.67},
                {'currency': 'BRL', 'gross_assets_curr': 100000, 'gross_assets_base_curr': 17857.14, 'percent':0.55, name: 'BRL', 'value': 0.55},
            ],
            category    : 'Performance',
            init_width  : 6,
            init_height : 6,
        }
    },

    computed: {
        inner_settings(){
            return {
                'currency' : this.currency,
            }
        },

        bar_options(){
            let x_names = []
            let data = []
            this.widget_data.forEach(r => {
                x_names.push(r.name)
                data.push(r)
            })

            let options = {
                xAxis: {
                    type: 'category',
                    data: x_names,
                },
                yAxis: {
                    type: 'value',
                    axisLabel: { show: false },
                },
                series: [ {
                    type: 'bar',
                    data: data,
                    label: {
                        show: true,
                        position: 'inside',
                        fontSize:12,
                        formatter: function(d) {
                          return num_to_money(d.value,2);
                        }
                    },
                }],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        var tar = params[0];
                        return '<b>' + tar.name + '</b><br>'
                            + 'gross assets, curr: ' + num_to_money(tar.data.gross_assets_curr,2) + '<br>'
                            + 'gross assets, base curr: ' + num_to_money(tar.data.gross_assets_base_curr,2) + '<br>'
                            + '%%: ' + num_to_money(tar.data.value,2);
                    }
                },
                grid: {
                    containLabel: true
                },
            }

            return options
        },

        pie_options(){
            let options = {
                legend: {
                    orient: 'vertical',
                    right: 5,
                    top: 0,
                    bottom: 20,
                },
                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: (params) => {
                        var tar = params;
                        return '<b>' + tar.name + '</b><br>'
                            + 'gross assets, curr: ' + num_to_money(tar.data.gross_assets_curr, 2) + '<br>'
                            + 'gross assets, base curr: ' + num_to_money(tar.data.gross_assets_base_curr, 2) + '<br>'
                            + '%%: ' + num_to_money(tar.data.value, 2);
                    }
                },
                series: [{
                    type: 'pie',
                    center: ['35%', '50%'],
                    label: {
                        show:false
                    },
                    labelLine: {
                        show: false
                    },
                    data: this.widget_data,
                    animationEasing: 'cubicInOut',
                    animationDuration: 2600
                }]
            }

            return options
        },

        table_options(){
            let options = {
                columns: [
                    { 
                        field: 'currency', 
                        title: 'curr' 
                    },
                    { 
                        field: 'gross_assets_curr', 
                        title: 'gross assets, curr', 
                        slots: { default: 'col_num' },
                        params   : {
                            format   : {
                                decimals : 2,
                                percents : false,
                                color    : true,
                            },
                        },
                        align:'right',
                    },
                    { 
                        field: 'gross_assets_base_curr', 
                        title: 'gross assets, base curr', 
                        slots: { default: 'col_num' },
                        params   : {
                            format   : {
                                decimals : 2,
                                percents : false,
                                color    : true,
                            },
                        },
                        align:'right',
                    },
                    { 
                        field: 'percent', 
                        title: '%%', 
                        slots: { default: 'col_num' },
                        params   : {
                            format   : {
                                decimals : 2,
                                percents : true,
                                color    : true,
                            },
                        },
                        align:'right',
                        width: '80',
                    },
                ],
                data: this.widget_data,
            }

            return options
        },
    },

    methods: {
        get_page_widget_data(){
            if (this.src === 'page' && this.client_id) {

                this.loading = true
                let params = {
                    client_id : this.client_id,
                }
                if (this.portfolio_id)
                    params.portfolio_id = this.portfolio_id;
                else
                    params.currency = this.currency;

                this.$store.dispatch('widgets/getCurrencyExposure', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
        },

        process_widget_db_data(data){
            if (data) {
                this.preview_mode = false;
                data.forEach(record => {
                    record.name = record.currency
                    record.value = record.percent
                })
                this.widget_db_data = data
            }
        },

        check_save_available(){
            if (this.$refs.Widget.settings_data.name && this.$refs.Widget.settings_data.client_id) {
                this.preview_mode = false
                this.loading = true
                let params = {
                    client_id : this.$refs.Widget.settings_data.client_id,
                }
                if (this.$refs.Widget.settings_data.portfolio_id)
                    params.portfolio_id = this.$refs.Widget.settings_data.portfolio_id;
                else
                    params.currency = this.$refs.Widget.settings_data.currency;

                this.$store.dispatch('widgets/getCurrencyExposure', params).then((response) => {
                    this.process_widget_db_data(response._items)
                    this.loading = false
                })
            }
            else {
                this.preview_mode = true;
            }
        }
    },
    
}
</script>
