<template>
    <div style="position:relative;height: 100%;" v-observe-visibility="resize_visible" v-resize @resize="onContainerResize">
        <div class="echarts" style="height:100%; width:100%;"></div>
        <div class="echart-preview-overlay" v-if="preview"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
require('echarts/theme/macarons')
import themed_chart from '@/mixins/themed_chart'
import chart from '@/mixins/chart'

export default {
    props: {
        options: {
            type    : Object,
            default : () => {},
        },
        charttype: {
            type     : String,
            required : true,
        },
        preview: {
            type: Boolean,
            default: false,
        },
    },

    mixins: [themed_chart, chart],

    data() {
        return {
            chart: null,
            rendering: false,
        }
    },

    methods: {
        resize_visible(){
            if(this.chart) {
                this.chart.resize()
            }
        },

        initChart() {
            this.clearChart();
            this.chart = echarts.init(this.$el.querySelectorAll('.echarts')[0], 'macarons')

            let options = Object.assign({}, this.options)
            options.grid = {
                top: 10,
                bottom: 30
            }

            options.textStyle = {
                fontFamily: 'Lato'
            }

            if(this.charttype === 'bar') {

                options.yAxis.position = 'insideBottom'

                options.series = options.series.map(series => {
                    if(series.type === 'bar'){
                        series.itemStyle = {
                            color: this.bar_chart_settings[this.current_theme].bar_bg_color
                        }
                        // if(series.data) series.data.sort()
                    }

                    return series
                })

                if (!options.xAxis.axisLabel)
                    options.xAxis.axisLabel = {};
                if (!options.yAxis.axisLabel)
                    options.yAxis.axisLabel = {};

                options.yAxis.axisLabel.color = this.bar_chart_settings[this.current_theme].label_color
                options.xAxis.axisLabel.color = this.bar_chart_settings[this.current_theme].label_color

                options.xAxis.splitArea = {
                    areaStyle: {
                        color: this.bar_chart_settings[this.current_theme].bg_colors
                    }
                }

                options.xAxis.splitLine = {
                    lineStyle: {
                        color: this.bar_chart_settings[this.current_theme].bg_splitter,
                        width: 1,
                    }
                }

                options.yAxis.splitArea = {
                    areaStyle: {
                        color: this.bar_chart_settings[this.current_theme].bg_colors
                    }
                }

                options.yAxis.splitLine = {
                    lineStyle: {
                        color: this.bar_chart_settings[this.current_theme].bg_splitter,
                        width: 1,
                    }
                }

                options.series.forEach(serie => {
                    if ('label' in serie && serie.label && serie.label.show) {
                        serie.label.fontSize = 12
                        serie.label.color = this.bar_chart_settings[this.current_theme].series_label_color
                        // let has_negative = serie.data.some(v => v < 0);
                        // if (has_negative)
                        //     serie.label.position = 'insideRight';
                        // else
                        //     serie.label.position = 'insideLeft';
                        serie.label.position = 'inside'
                    }
                })
            }

            if ('tooltip' in options) {
                options.tooltip.backgroundColor = this.bar_chart_settings[this.current_theme].bg_colors[1]
                options.tooltip.borderColor = this.bar_chart_settings[this.current_theme].bg_colors[1]
                options.tooltip.textStyle = {
                    color: this.bar_chart_settings[this.current_theme].label_color
                }
            }

            this.chart.setOption(options)
        },

        clearChart(){
            if (!this.chart) {
                return
            }
            this.chart.dispose()
            this.chart = null
        },

        onContainerResize(){
            this.$_resizeHandler()
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.initChart();
        })
    },

    watch: {
        options(val, prevVal) {
            //check if new settings equal to old. If they are equal, widget wont be rendered
            if(
                ![val, prevVal].every(obj => JSON.stringify(obj) === JSON.stringify([val, prevVal][0]))
            ){
                this.initChart();
            }
        },
        current_theme(){
            this.initChart();
        },
        charttype(val) {
            this.initChart();
        },
    }
}
</script>
